<nz-layout class="w-full h-full" style="background: #F5FAFF;">
  <div class="absolute bottom-0 max-h-[24.5rem] left-[50%] translate-x-[-50%] w-full overflow-hidden">
    <img class="mx-auto" src="../../../assets/images/corefluens/LAYOUT_LOGO.svg" alt="LAYOUT_LOGO">
  </div>
  <nz-sider
    class="bg-white hide-scroollbar"
    [nzTrigger]="null"
    nzCollapsible
    [(nzCollapsed)]="collapse"
    [nzWidth]="'225px'"
    (mouseenter)="collapse = false; trickyBtn.click()"
    (mouseleave)="collapse = true; closeAllCollapsibleAreas()"
    >
    <button #trickyBtn hidden></button>

    <div class="px-[1rem] h-[100dvh] flex flex-col">
      <div class="logo pt-4 mb-[3.1rem] flex" >
        <img
          [src]="collapse? '/assets/images/corefluens/OR.svg' : '/assets/images/corefluens/COREFLUENS.svg'"
          class="w-full h-8 block group-hover:hidden mx-auto max-w-[150px]"
          alt="corefluens-logo">
      </div>

      <div class="flex items-center ml-[0.2rem] gap-2 mb-[1rem] menus-title">
        <span class="min-w-[0.6rem] min-h-[0.6rem] bg-secondary rounded-full"></span>
        <p class="font-sans-bold text-14 whitespace-nowrap">Originador de crédito</p>
      </div>

      <core-collapsible-menu
      *ngIf="optionExists('asesores')"
      [id]="collapsibleMenus[0].id"
      title="Asesores"
      [isOpen]="collapsibleMenus[0].isOpen"
      (activeMenuEvent)="accordionBehavior($event)">
        <fa-icon
          menu-icon
          [icon]="['fas','user-headset']"
          class="text-12 text-secondary ml-[0.5rem]">
        </fa-icon>
        <div menu-content>
          <div class="menu-item cursor-pointer" routerLink="/inicio/asesores/prospectos" *ngIf="optionExists('prospectos')">
            <div class="menu-item-icon">
              <fa-icon
                [icon]="['fal','users-viewfinder']"
                class="text-12 ml-[0.1rem]"></fa-icon>
            </div>
            <p class="menu-item-text text-12 text-black">Prospectos</p>
          </div>
          <!-- <div class="menu-item cursor-pointer" routerLink="/en-desarrollo" *ngIf="optionExists('gestiones')">
            <div class="menu-item-icon">
              <fa-icon
                [icon]="['fal','list-check']"
                class="text-12 ml-[0.1rem]"></fa-icon>
            </div>
            <p class="menu-item-text text-12 text-black">Gestiones</p>
          </div>
          <div class="menu-item cursor-pointer" routerLink="/en-desarrollo" *ngIf="optionExists('nuevo credito')">
            <div class="menu-item-icon">
              <fa-icon
                [icon]="['fal','circle-plus']"
                class="text-12 ml-[0.1rem]"></fa-icon>
            </div>
            <p class="menu-item-text text-12 text-black">Nuevo crédito</p>
          </div> -->
        </div>
      </core-collapsible-menu>

      <core-collapsible-menu
        *ngIf="optionExists('administrativos')"
        [id]="collapsibleMenus[1].id"
        title="Administrativos"
        [isOpen]="collapsibleMenus[1].isOpen"
        (activeMenuEvent)="accordionBehavior($event)">
        <fa-icon
          menu-icon
          [icon]="['fas','users']"
          class="text-12 text-secondary ml-[0.5rem]"></fa-icon>
          <div menu-content>
            <div class="menu-item cursor-pointer" routerLink="/inicio/administrativos/originacion" *ngIf="optionExists('originacion')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','network-wired']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Originación</p>
            </div>
            <div class="menu-item cursor-pointer" routerLink="/inicio/administrativos/cartera" *ngIf="optionExists('cartera')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','circle-dollar']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Cartera</p>
            </div>
          </div>
      </core-collapsible-menu>

      <core-collapsible-menu
        *ngIf="optionExists('administrador')"
        [id]="collapsibleMenus[2].id"
        title="Administrador"
        [isOpen]="collapsibleMenus[2].isOpen"
        (activeMenuEvent)="accordionBehavior($event)">
        <fa-icon
          menu-icon
          [icon]="['fas','user-pen']"
          class="text-12 text-secondary ml-[0.5rem]"></fa-icon>
          <div menu-content>
            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/productos" *ngIf="optionExists('productos')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','kaaba']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Productos</p>
            </div>


            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/lineas" *ngIf="optionExists('lineas')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','sliders-up']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Líneas</p>
            </div>

            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/reglas" *ngIf="optionExists('reglas')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','ruler-triangle']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Reglas</p>
            </div>

            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/documento-de-solicitud" *ngIf="optionExists('documentos de solicitud')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','file-user']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Documentos de solicitud</p>
            </div>

            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/documento-de-credito" *ngIf="optionExists('documentos de creditos')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','file-lines']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Documentos de crédito</p>
            </div>

            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/plan-de-pagos" *ngIf="optionExists('plan de pagos')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','file-invoice-dollar']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Plan de pagos</p>
            </div>

            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/flujo-de-originacion" *ngIf="optionExists('flujos de credito')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','arrow-progress']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Flujos de originación</p>
            </div>

            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/costos-cuotas" *ngIf="optionExists('costos cuotas')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','badge-percent']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Costos en cuotas</p>
            </div>

            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/tarifas-tasas" *ngIf="optionExists('tarifas y tasas indexadas')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','calculator-simple']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Tarifas y tasas indexadas</p>
            </div>


            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/usuarios" *ngIf="optionExists('usuarios')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','user']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Usuarios</p>
            </div>

            <div class="menu-item cursor-pointer" routerLink="/inicio/administrador/costos/facturacion" *ngIf="optionExists('facturación')">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','file-invoice-dollar']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Facturación</p>
            </div>
          </div>
      </core-collapsible-menu>

      <!-- <div class="flex items-center ml-[0.2rem] gap-2 mb-[1rem] menus-title mt-[2.1rem]">
        <span class="min-w-[0.6rem] min-h-[0.6rem] bg-secondary rounded-full"></span>
        <p class="font-sans-bold text-14 whitespace-nowrap">Administrador de cartera</p>
      </div> -->

      <!-- routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}" -->
      <!-- <div class="menu-item cursor-pointer" routerLink="en-desarrollo">
        <div class="menu-item-icon">
          <fa-icon
            [icon]="['fal','circle-dollar']"
            class="text-12 ml-[0.1rem]"></fa-icon>
        </div>
        <p class="menu-item-text text-12 text-black">Saldos</p>
      </div>

      <div class="menu-item cursor-pointer" routerLink="en-desarrollo" >
        <div class="menu-item-icon">
          <fa-icon
            [icon]="['fal','arrow-up-from-bracket']"
            class="text-12 ml-[0.1rem]"></fa-icon>
        </div>
        <p class="menu-item-text text-12 text-black">Cargue de cartera</p>
      </div> -->

      <!-- <core-collapsible-menu
        [id]="collapsibleMenus[3].id"
        title="Abonos"
        [isOpen]="collapsibleMenus[3].isOpen"
        (activeMenuEvent)="accordionBehavior($event)">
        <fa-icon
          menu-icon
          [icon]="['fas','sack-dollar']"
          class="text-12 text-secondary ml-[0.5rem]"></fa-icon>
          <div menu-content>
            <div class="menu-item cursor-pointer" routerLink="/en-desarrollo">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','box']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Caja</p>
            </div>
            <div class="menu-item cursor-pointer" routerLink="/en-desarrollo">
              <div class="menu-item-icon">
                <fa-icon
                  [icon]="['fal','hand-holding-dollar']"
                  class="text-12 ml-[0.1rem]"></fa-icon>
              </div>
              <p class="menu-item-text text-12 text-black">Reestructurador</p>
            </div>
          </div>
      </core-collapsible-menu> -->

      <div class="menu-item cursor-pointer mt-auto logout" (click)="logout()">
        <div class="menu-item-icon ">
          <fa-icon
            [icon]="['fal','person-from-portal']"
            class="text-16 ml-[0.1rem] text-gray-7"
            ></fa-icon>
        </div>
        <p class="menu-item-text text-12 text-auth-navy-blue">Cerrar sesión</p>
      </div>

      <div class="menu-item cursor-pointer mb-[1rem] overflow-hidden !min-h-[2.6rem] !min-w-[2.6rem]">
        <div class="menu-item-icon bg-secondary">
          <span class="font-sans-bold text-12 text-white"> {{ userData.initial }} </span>
        </div>
        <p class="menu-item-text text-12 text-auth-navy-blue ml-[0.7rem]"> {{ userData.name }} </p>
      </div>
    </div>

  </nz-sider>

  <nz-layout cdkScrollable style="background: transparent;">
    <div
      cdkScrollable
      [@fadeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''"
      class="max-h-screen w-full relative overflow-auto pt-[2.5rem] pr-5 pl-17.5 xl:pl-5 lg:pr-17.5"
      updateOnScroll>
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </nz-layout>
</nz-layout>

<!--! Notification -->
<ng-template #templateNotification>
  <div class="ant-notification-notice-content">
    <div class="ant-notification-notice-with-icon">
      <span class="ant-notification-notice-icon">
        <fa-icon [icon]="['fas','circle-exclamation']" class="text-danger text-16"> </fa-icon>
      </span>
      <div class="ant-notification-notice-description !ml-8 !text-14">
        Si no se detecta actividad en los próximos 5 minutos su sesión terminará.
      </div>
    </div>
  </div>
</ng-template>
<ng-template #customCloseIcon class="custom-close-icon">
  <fa-icon [icon]="['fal','xmark']" class="text-danger text-[2rem]"> </fa-icon>
</ng-template>
